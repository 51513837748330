
import { defineComponent, reactive, onMounted } from "vue";
import { ScrollTopComponent } from "@/assets/ts/components/_ScrollTopComponent";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useBillStatus from "@/core/services/compositions/enum/useBillStatus";
import useBillType from "@/core/services/compositions/enum/useBillType";

interface Filter {
  invoice_number: string;
  va_number: string;
  bcn: string;
  type: string;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  status: string;
  due_date: string;
  valid_until: string;
  date: Array<string>;
}

export default defineComponent({
  name: "filter-bill",
  components: {},
  setup(props, context) {
    const { billStatus } = useBillStatus();
    const { billType } = useBillType();
    const { query } = useQuery();

    const data = reactive<Filter>({
      invoice_number: query.value.invoice_number || '',
      va_number: query.value.va_number || '',
      bcn: query.value.bcn || '',
      type: query.value.type || '',
      customer_name: query.value.customer_name || '',
      customer_email: query.value.customer_email || '',
      customer_phone: query.value.customer_phone || '',
      status: query.value.status || '',
      due_date: query.value.due_date || '',
      valid_until: query.value.valid_until || '',
      date: query.value.date || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.invoice_number = '';
      data.va_number = '';
      data.bcn = '';
      data.type = '';
      data.customer_name = '';
      data.customer_email = '';
      data.customer_phone = '';
      data.status = '';
      data.due_date = '';
      data.valid_until = '';
      data.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    };

    onMounted(() => {
      ScrollTopComponent.reinitialization();
    });

    return {
      data,
      filter,
      reset,
      billStatus,
      billType,
      screen
    };
  }
});
