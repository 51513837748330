
import { reactive, defineComponent, computed, ref, onMounted, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Pagination from "@/components/Pagination.vue";
import {
  isUndefined,
  useQuery,
  useMutateQuery,
  useHasRole
} from "@/core/helpers/common-helper";
import { convertToFullDateTimeWithoutSecond } from "@/core/helpers/date-helper";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { useI18n } from "vue-i18n";
import useBillList from "@/core/services/compositions/bill/useBillList";
import { numberFormat } from "@/core/helpers/currency-helper";
import FilterBill from "./filter-bill.vue";
import { DrawerComponent } from "@/assets/ts/components/_DrawerOptions";
import { MenuComponent } from "@/assets/ts/components";
import moment from 'moment'
import { ElNotification } from 'element-plus'
import useDownload from "@/core/helpers/download";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterBill,
  },
  setup() {
    // init
    const { t } = useI18n();

    // breadcrumb
    setNewPageBreadcrumbs(t("bill.breadcrumb"), [
      { title: t("bill.title") }
    ]);

    const paginationRef = ref(null);
    const dialog = ref(false);
    const loading = ref(false);

    const form = reactive({
      invoice_number: '',
      va_number: '',
      bcn: '',
      type: '',
      customer_name: '',
      customer_email: '',
      customer_phone: '',
      status: '',
      due_date: '',
      valid_until: '',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      bill: '',
    });

    const { query } = useQuery();
    const { mutateQuery } = useMutateQuery();
    const roleUtils = useHasRole();
    const router = useRouter();

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      invoice_number: query.value.invoice_number || form.invoice_number,
      va_number: query.value.va_number || form.va_number,
      bcn: query.value.bcn || form.bcn,
      type: query.value.type || form.type,
      customer_name: query.value.customer_name || form.customer_name,
      customer_email: query.value.customer_email || form.customer_email,
      customer_phone: query.value.customer_phone || form.customer_phone,
      status: query.value.status || form.status,
      due_date: query.value.due_date || form.due_date,
      valid_until: query.value.valid_until || form.valid_until,
      date: query.value.date || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      bill: query.value.bill || form.bill,
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));
    const { data, isValidating, error } = useBillList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          invoice_number: '',
          va_number: '',
          bcn: '',
          type: '',
          customer_name: '',
          customer_email: '',
          customer_phone: '',
          status: '',
          due_date: '',
          valid_until: '',
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          order_by: 'created_at',
          order: 'DESC',
          bill: '',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        });
      }
    });

    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage
      });
    };

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);
      mutateQuery({
        page: 1,
        invoice_number: form.invoice_number,
        va_number: form.va_number,
        bcn: form.bcn,
        type: form.type,
        customer_name: form.customer_name,
        customer_email: form.customer_email,
        customer_phone: form.customer_phone,
        status: form.status,
        due_date: form.due_date,
        valid_until: form.valid_until,
        date: form.date,
        order_by: 'created_at',
        order: 'DESC',
        bill: '',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const openDrawer = () => {
      const drawerEl = document.getElementById("kt_drawer_filter");

      if (!drawerEl) {
        return;
      }

      DrawerComponent.getInstance(drawerEl).toggle();
    };

    const { handleDownloadToFile, isDownloading } = useDownload();

    const download = (file) => {
      handleDownloadToFile(`/app/bill`, file,
        {
          download: true,
          invoice_number: query.value.invoice_number || form.invoice_number,
          va_number: query.value.va_number || form.va_number,
          bcn: query.value.bcn || form.bcn,
          type: query.value.type || form.type,
          customer_name: query.value.customer_name || form.customer_name,
          customer_email: query.value.customer_email || form.customer_email,
          customer_phone: query.value.customer_phone || form.customer_phone,
          status: query.value.status || form.status,
          due_date: query.value.due_date || form.due_date,
          valid_until: query.value.valid_until || form.valid_until,
          date: query.value.date || form.date,
          order_by: 'created_at',
          order: 'DESC',
        }
      );
    }

    const gotoPayment = (id) => {
      router.push({ name: "app.payment", query: { bill: id, date: ['', ''] } });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      ...roleUtils,
      data,
      isValidating,
      isUndefined,
      convertToFullDateTimeWithoutSecond,
      handleChangePage,
      paginationRef,
      dialog,
      form,
      loading,
      filtered,
      numberFormat,
      openDrawer,
      moment,
      download,
      isDownloading,
      gotoPayment
    };
  },
  methods: {}
});
